import { useContext } from "react"
import { UserContext } from "../../context/UserContext"
import { Outlet } from "react-router-dom";
import VideoScreen from "../../views/VideoScreen/VideoScreen";

const Layout = (props) => {
    const myState = useContext(UserContext);    
    const [rightState] = myState.rightState;

    
    return(
        <div className="grid grid-cols-6 container-home ">
            <div className="col-start-1 col-end-5 h-screen overflow-y-auto bg-[#f2f2f2]">					
                <VideoScreen/>
                <Outlet/>
            </div>
            <div className="col-start-5 col-end-7 bg-[#E9E9E9] h-screen">
                { rightState.content}
            </div>
        </div>
    )
}
export default Layout