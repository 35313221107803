import {  
    // useRef,  useEffect,  useCallback, 
    // useState, 
    useContext, useEffect, useRef } from 'react';
// import config from '../../config';
// import api from '../../api';
// import { w3cwebsocket as W3CWebSocket } from "websocket";
// import Cookies from 'universal-cookie';
// import { useReactToPrint } from 'react-to-print';
import '../../custom.css';
import "ninja-keys";
import { useGlobalFunction } from '../../generalFunction/generalFunction';
import { UserContext } from '../../context/UserContext';
import FormInputInvoice from './Component/FormInputInvoice';
import { useNavigate } from 'react-router';


const Numpad = ({ 
    // handleChangeInput, 
    // handleDeleteInput, 
    handleSubmit,
    // handleChange, 
    // inputRef,  
    handleKeyDownInput, 
    // handleClear,    
}) => {
    const myState = useContext(UserContext);
    const [inputHp, setInputHp] = myState.inputHp;
    const inputRef = useRef(null);

    const handleChangeInput = (e) => {        
        setInputHp(inputHp + e)    
        handleFocus();    
    }

    const handleChange = (e) => {
        const { value } = e.target;
        setInputHp(value);
        handleFocus();
    }

    const handleClear = () => {
        setInputHp('');
        handleFocus();
    }

    const handleDeleteInput = (e) => {
        let tmp = inputHp;
        tmp = tmp.slice(0, -1);
        setInputHp(tmp);
        handleFocus();
    }

    const handleFocus = () => {
        // if (isShow){
            inputRef.current.focus();
        // }
    };


	return (
        <div className='flex flex-col min-h-screen'>
            <div className='bg-white flex-1 mx-[10px] mt-[22px] p-[22px]'>                
                <FormInputInvoice
                    handleChange={handleChange}
                    inputRef={inputRef}
                    formInput={inputHp}
                    handleKeyDownInput={handleKeyDownInput}
                    handleClear={handleClear}
                />
                <div className='text-center mt-24 text-[34px]'>{inputHp}</div>
            </div>
            
            <div className='bg-[#E9E9E9] pb-10'>
                <div className='grid grid-cols-4 overflow-hidden p-2 gap-2'>
                    <div onClick={() => handleChangeInput(1)} className='cursor-pointer text-center px-2 py-4 rounded-sm bg-white text-[18px]'>1</div>
                    <div onClick={() => handleChangeInput(2)} className='cursor-pointer text-center px-2 py-4 rounded-sm bg-white text-[18px]'>2</div>
                    <div onClick={() => handleChangeInput(3)} className='cursor-pointer text-center px-2 py-4 rounded-sm bg-white text-[18px]'>3</div>

                    <div onClick={handleDeleteInput} className='cursor-pointer text-center px-2 py-4 rounded-sm bg-white text-[18px]'>
                        <img alt='icon' src={require('../../assets/icon/backspace-arrow.png')} className='mx-auto' />
                    </div>
                    <div onClick={() => handleChangeInput(4)} className='cursor-pointer text-center px-2 py-4 rounded-sm bg-white text-[18px]'>4</div>
                    <div onClick={() => handleChangeInput(5)} className='cursor-pointer text-center px-2 py-4 rounded-sm bg-white text-[18px]'>5</div>
                    <div onClick={() => handleChangeInput(6)} className='cursor-pointer text-center px-2 py-4 rounded-sm bg-white text-[18px]'>6</div>

                    <div onClick={() => handleSubmit(inputHp)} className='cursor-pointer text-center px-2 py-4 rounded-sm bg-white text-[18px]'>
                        <img alt='icon' src={require('../../assets/icon/enter.png')} className='mx-auto' />
                    </div>
                    <div onClick={() => handleChangeInput(7)} className='cursor-pointer text-center px-2 py-4 rounded-sm bg-white text-[18px]'>7</div>
                    <div onClick={() => handleChangeInput(8)} className='cursor-pointer text-center px-2 py-4 rounded-sm bg-white text-[18px]'>8</div>
                    <div onClick={() => handleChangeInput(9)} className='cursor-pointer text-center px-2 py-4 rounded-sm bg-white text-[18px]'>9</div>
                    <div className='text-center px-2 py-4 rounded-sm bg-white text-[18px]'></div>

                    <div className='text-center px-2 py-4 rounded-sm bg-white text-[18px]'></div>
                    <div onClick={() => handleChangeInput(0)} className='cursor-pointer text-center px-2 py-4 rounded-sm bg-white text-[18px]'>0</div>
                    <div className='text-center px-2 py-4 rounded-sm bg-white text-[18px]'></div>
                    <div className='text-center px-2 py-4 rounded-sm bg-white text-[18px]'></div>			
                </div>
            </div>						
        </div>
	)
}


export function useMyHome() {
    const navigate = useNavigate();    
    const myState = useContext(UserContext);    
    
    const [developmentMode] = myState.developmentMode;
    const [rightState, rightDispatch] = myState.rightState;
    const [showVideo, setShowVideo] = myState.showVideo;
    const { alertValue, handleCloseAlert } = useGlobalFunction();
    

    
    useEffect(() => {
        setShowVideo(true);
    },[setShowVideo])
    

    const handleGetInvoiceInfo = async (value) => {
        if (value === '000000'){
            navigate('/daftar-transaksi')
        }else{
            navigate('/payment-method')
        }        
    }


    

    const handleKeyDownInput = (e, value) => {
        
        if (e.key === 'Enter') {
            if (value === '000000'){
                navigate('/daftar-transaksi')
            }else{
                handleGetInvoiceInfo();
            }
        }
    }

    
    useEffect(() => {
        rightDispatch({
            type: 'OTHERS',
            content : <Numpad
                handleKeyDownInput={(e, value) => handleKeyDownInput(e, value)}
                handleSubmit={(value) => handleGetInvoiceInfo(value)}
            />
        })
        // eslint-disable-next-line 
    },[])




    return {
        alertValue,
		developmentMode,
        handleCloseAlert,
        rightState,
        showVideo,
		// videoRef, 
		// videoLoop, inputRef, videoIndex, formInput,
		// handleNextVideo, handleKeyDownInput, handleChangeInput,
		// handleDeleteInput, handleChange, 
		// handleCloseAlert, 
		// handleGetInvoiceInfo,
		// leftScreenType, 
		// handleClear,
    }

}