import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useGlobalFunction} from "../../generalFunction/generalFunction";
import api from "../../api";
import config from "../../config";
import CusDetailComp from "../Home/Component/CusDetailComp";
import {UserContext} from "../../context/UserContext";
import Cookies from "universal-cookie";
import {useNavigate} from "react-router";
import {useReactToPrint} from "react-to-print";

export const ACK = "ACK",
    NAK = "NAK",
    DATA = "EDC_DATA",
    APPROVE= "00",
    DECLINE_EXPIRED_CARD = "54",
    DECLINE_INCORRECT_PIN = "55",
    READ_CARD_ERROR = "P2",
    USER_PRESS_CANCEL = "P3",
    EMV_CARD_DECLINE = "Z3",
    CONNECTION_ERROR = "CE",
    CONNECTION_TIMEOUT = "TO",
    EDC_PROBLEM = "PT",
    DECLINE = "aa",
    ERROR_TRY_AGAIN = "S2",
    TNX_SCAN_QR = "S3",
    TNX_EXPIRED_TRY_GAIN = "S4",
    TOP_UP_NOT_READY = "TN"

export function usePaymentEdcBcaFunction() {


    const cookies = new Cookies();
    const [alertValue, setAlertValue] = useState({
        show: false,
        color: 'danger',
        text: '',
    });


    const myState = useContext(UserContext);
    const [isOnline, setIsOnline] = useState(false);
    const [rightState, rightDispatch] = myState.rightState;

    const { isLoading, setIsLoading } = useGlobalFunction();
    let tmpCustomerDetail = sessionStorage.getItem('customerDetail') ? JSON.parse(sessionStorage.getItem('customerDetail')) : null
    const [customerDetail, setCustomerDetail] = useState(tmpCustomerDetail);
    const navigate = useNavigate();
    const [isPaid, setIsPaid] = useState(false);
    const [isApprove, setApprove] = useState(false);
    const [token, setToken] = useState('');
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const [isTryAgain, setTryAgain] = useState(false);
    const componentRef = useRef();
    const ws = useRef(null);
    const respCountRef = useRef(0);
    const errorCountRef = useRef(0);

    const handleCancel = () => {
        sessionStorage.removeItem('customerDetail')
        navigate('/')
        // window.location.reload()
    }

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({ ...alertValue, show: false }))
    }

    const fetchData = useCallback(async(formInput) => {
        try{
            let newForm = new FormData();
            let tmp = {
                'noinvoice': formInput
            }
            newForm.append('formData', JSON.stringify(tmp))
            const res = await api.post(`${config.endPoint.invoiceInfo}`, newForm).then(res => res.data);
            console.log('res is >>>>', res);
            if (res){
                let tmp = {...res.results}
                tmp['logo'] = res.logo;
                tmp['ahass_name'] = res.ahass_name
                setCustomerDetail(tmp);
                rightDispatch({
                    type: 'OTHERS',
                    content : <CusDetailComp
                        customerDetail={res.results}
                    />
                })

                ws.current.send(JSON.stringify({
                    message: 'transaction',
                    amount: res.results.total
                }))
            }
        }catch(error){

        }

    },[rightDispatch])

    useEffect(() => {
        fetchData(sessionStorage.getItem('inputHp'))
    }, [fetchData])

    useEffect(() => {
        const myCookies = cookies.get(config.cookiesName);
        if (myCookies && myCookies.token) {
            setToken(myCookies.token);
            // openWs(myCookies.token)
        }

        // eslint-disable-next-line
    }, [])

    const printThePage = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        if (token){
            if (waitingToReconnect) {
                return;
            }

            // Only set up the websocket once
            if (!ws.current) {
                const client = new WebSocket(config.apiBaseUrl.wsServer + token + '/');
                ws.current = client;

                window.client = client;

                client.onerror = (e) => console.error(e);

                client.onopen = () => {
                    console.log('ws opened >>>>');
                    setIsOnline(true);
                };

                client.onclose = () => {

                    if (ws.current) {
                        // Connection failed
                        console.log('ws closed by server');
                        setIsOnline(false);
                    } else {
                        // Cleanup initiated from app side, can return here, to not attempt a reconnect
                        console.log('ws closed by app component unmount');
                        return;
                    }

                    if (waitingToReconnect) {
                        return;
                    }

                    // Parse event code and log
                    console.log('ws closed');

                    // Setting this will trigger a re-run of the effect,
                    // cleaning up the current websocket, but not setting
                    // up a new one right away
                    setWaitingToReconnect(true);

                    // This will trigger another re-run, and because it is false,
                    // the socket will be set up again
                    setTimeout(() => setWaitingToReconnect(null), 2000);
                };

                client.onmessage = messageEvent => {
                    const message = JSON.parse(messageEvent.data);

                    if (message && message.text && message.text.type === 'payment_edc_bca_notification' && message.text.event === 'transaction_response') {

                        respCountRef.current += 1;
                        console.log(respCountRef.current);

                        if (respCountRef.current >= 3) {
                            respCountRef.current = 0;
                            return;
                        }

                        if (respCountRef.current !== 1) {
                            return;
                        }

                        const data = message.text.data;

                        const respCode = data.respCode;

                        console.log(respCode)

                        if (respCode === APPROVE) {
                            setApprove(true)
                            return;
                        }

                        const respCodeMessages = {
                            [ERROR_TRY_AGAIN]: "Gagal, Mohonn tunggu, Sedang mencoba lagi",
                            [TNX_EXPIRED_TRY_GAIN]: "Transaction Kadaluarsa, Mohonn tunggu, Sedang mencoba lagi",
                            [CONNECTION_ERROR]: "Koneksi error, Mohonn tunggu, Sedang mencoba lagi",
                            [CONNECTION_TIMEOUT]: "Waktu Habis, Mohonn tunggu, Sedang mencoba lagi",
                            [DECLINE_EXPIRED_CARD]: "Kartu anda Kadaluarsa, Mohonn tunggu, Sedang mencoba lagi",
                            [DECLINE_INCORRECT_PIN]: "PIN kartu di tolak, Mohonn tunggu, Sedang mencoba lagi",
                            [READ_CARD_ERROR]: "Gagal membaca kartu, Mohonn tunggu, Sedang mencoba lagi",
                            [EMV_CARD_DECLINE]: "EMV kartu di tolak, Mohonn tunggu, Sedang mencoba lagi",
                            [EDC_PROBLEM]: "Mesin EDC bermasalah, silahkan hubungi customer service",
                            [DECLINE]: "Transaksi ditolak, Mohonn tunggu, Sedang mencoba lagi",
                            [TNX_SCAN_QR]: "Scan QR, Gagal, Mohonn tunggu, Sedang mencoba lagi",
                            [TOP_UP_NOT_READY]: "Top up tidak tersedia, silahkan hubungi customer service"
                        };

                        const errMessage = respCodeMessages[respCode] || "Kode respons tidak dikenal";

                        setAlertValue({
                            show: true,
                            text: errMessage,
                            color: 'danger',
                        })

                        if (respCode === USER_PRESS_CANCEL) {
                            setAlertValue({
                                show: true,
                                text: 'Pengguna membatalkan transaksi, Sedang mengalihkan ke beranda....',
                                color: 'danger',
                            })

                            setTimeout(() => {
                                handleCancel()
                            }, 5000)
                            return;
                        }

                        setTimeout(() => {
                            setTryAgain(true)
                        }, 7000)
                    }
                };


                return () => {

                    console.log('Cleanup');
                    // Dereference, so it will set up next time
                    ws.current = null;

                    client.close();
                }
            }
        }
        // eslint-disable-next-line
    }, [waitingToReconnect, token]);

    // for start transaction
    useEffect(() => {
        if (!ws.current || !isOnline || !customerDetail) return;

        ws.current.send(JSON.stringify({
            message: {
                type: "payment_edc_bca_notification",
                event: "start_transaction",
                data: {
                    amount: customerDetail.total
                }
            }
        }))
    }, [ws, isOnline, customerDetail]);

    useEffect(() => {
        if (!ws.current || !isApprove || !customerDetail || !token) return;

        ws.current.onmessage = messageEvent => {

            const message = JSON.parse(messageEvent.data);

            if (message && message.text && message.text.type === 'payment_edc_bca_notification' && message.text.event === 'transaction_response') {
                const data = message.text.data;

                if (!isPaid && data.respCode === "00") {
                    console.log("token", token)
                    ws.current.send(JSON.stringify({
                        message: {
                            type: "payment_edc_bca_notification",
                            event: "transaction_approve",
                            data: {
                                no_invoice: customerDetail.noinvoice,
                                access_token: token,
                                json_success_payment: data
                            }
                        }
                    }))
                    console.log("send response approve to backend")
                    customerDetail.is_paid = false
                    setIsPaid(true)

                }
            }

        }
    }, [ws, isApprove, isPaid, customerDetail, token]);

    useEffect(() => {
        if (!isTryAgain) {
            return;
        }

        if (!ws.current || !isOnline || !customerDetail) return;

        console.log(errorCountRef)

        if (errorCountRef.current < 3) {
            ws.current.send(JSON.stringify({
                message: {
                    type: "payment_edc_bca_notification",
                    event: "start_transaction",
                    data: {
                        amount: customerDetail.total
                    }
                }
            }))

            setAlertValue({
                show: true,
                text: 'Silahkan coba lagi',
                color: 'success'
            })

            errorCountRef.current += 1;
            setTryAgain(false)
        } else {
            setAlertValue({
                show: true,
                text: '3x Percobaan gagal, harap hubungi customer service',
                color: 'danger'
            })

            setTimeout(() => {
                navigate('/')
            }, 5000)
        }



    }, [isTryAgain, ws, isOnline, customerDetail]);

    return {
        alertValue,
        isLoading,
        isPaid,
        customerDetail,
        componentRef,
        handleCancel,
        handleCloseAlert,
        printThePage
    }
}