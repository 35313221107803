import AlertComp from "../../component/alert/AlertComp";
import IsPaidComp from "../Home/Component/IsPaidComp";
import ListVaComp from "../Home/Component/ListVaComp";
import QrisComp from "../Home/Component/QrisComp";
import VaSelectedComp from "../Home/Component/VaSelectedComp";
import { usePaymentNonCash } from "./paymentNonCashFunction"


const PaymentNonCashPage = (props) => {
    const { 
        view, listVa, handleKembali, handleSubmitByVa,
        developmentMode, qrImage, isLoading, paymentUrl, handleCancelPayment,
        customerDetail, printThePage, componentRef, selectedVa, companyCode,
        vaNumber, alertValue, handleCloseAlert
    } = usePaymentNonCash();
    
    return(
        <div className="col-start-1 col-end-5 h-screen overflow-y-auto bg-[#f2f2f2]">
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
            />
            { view === 'list_va' &&
                <ListVaComp
                    listVa={listVa}
                    handleSubmitByVa={handleSubmitByVa}
                    handleCancel={handleKembali}	
                    isLoading={isLoading}						
                />
            }
            { view === 'qris' &&                
                <QrisComp
                    developmentMode={developmentMode}
                    qrImage={qrImage}
                    isLoading={isLoading}
                    paymentUrl={paymentUrl}
                    handleCancelPayment={handleCancelPayment}
                    handleCancel={handleKembali}
                    customerDetail={customerDetail}
                />            
            }
            { view === 'is_paid' &&
                <IsPaidComp
                    handleCancel={handleKembali}
                    printThePage={printThePage}
                    componentRef={componentRef}
                    customerDetail={customerDetail}
                />
            }
            { view === 'va_selected' &&
                <VaSelectedComp
                    handleCancel={handleKembali}
                    customerDetail={customerDetail}
                    selectedVa={selectedVa}
                    companyCode={companyCode}
                    vaNumber={vaNumber}
                    // expTime={expTime}
                    handleCancelPayment={handleCancelPayment}							
                    expTime={customerDetail?.json_data?.expiry_time}
                />
            }
        </div>
    )
}
export default PaymentNonCashPage