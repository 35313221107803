import {Fragment} from "react";
import PropTypes from "prop-types";

const PaymentChannelComp = (props) => {
    const { usingVa, handleEDCBCA, handleNonEDC } = props
    return (
        <Fragment>
            <div className="grid grid-cols-2 gap-4">
                <div className="cash text-center cursor-pointer" onClick={handleEDCBCA}>
                    <img src={require('../../../assets/icon/edc-bca-logo.png')} alt='icon'
                         className='h-[200px] w-auto text-center'/>
                    <div className='mt-5 font-semibold text-[18px]'>Kartu Debit BCA</div>
                </div>
                {!usingVa ?
                    <div className="cassless text-center ml-10 cursor-pointer" onClick={handleNonEDC}>
                        <img src={require('../../../assets/icon/cashless.png')} alt='icon'
                             className='h-[200px]'/>
                        <div className='mt-5 font-semibold text-[18px]'>QRIS</div>

                    </div>
                    :
                    <div className="cassless text-center ml-10 cursor-pointer" onClick={handleNonEDC}>
                        <img src={require('../../../assets/icon/cashless.png')} alt='icon'
                             className='h-[200px]'/>
                        <div className='mt-5 font-semibold text-[18px]'>Virtual Account</div>

                    </div>
                }
            </div>
        </Fragment>
    )
}

PaymentChannelComp.propTypes = {
    usingVa: PropTypes.bool,
    handleEDCBCA: PropTypes.func,
    handleNonEDC: PropTypes.func,
}

PaymentChannelComp.defaultProps = {
    usingVa: false,
}

export default PaymentChannelComp