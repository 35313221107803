import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from 'react';
// import IsPaidComp from './IsPaidComp';

const CashPaymentComp = (props) => {    
    const { ictReady, handleOpenMachine, ictText, listTunai, totalTunai, customerDetail, handleCancel,
        // isPaid, printThePage,  
        isOnline,
    } = props;
    return(
        <div className="flex flex-col  min-h-full bg-white overflow-y-auto pb-28">
            
            <Fragment>

                <div className={`px-5 pt-5 ${isOnline ? 'text-green-500' : 'text-gray-400'}`}>
                    <FontAwesomeIcon className='ml-3' icon={faCircle} /> { isOnline ? 'Online' : 'Offline' } 
                </div>
                <div className={`px-5 pt-5 ${ictReady ? 'text-green-500' : 'text-gray-400'}`}>
                    <FontAwesomeIcon icon={faCircle}/> { ictText }
                    <div className='mt-3'>
                        <button className="
                            button
                            text-[12px]                        
                            "
                            onClick={handleOpenMachine}
                        >Reload Mesin</button>
                    </div>
                </div>
                <div className='flex flex-col h-full md:container md:px-[200px] justify-center mt-10'>
                    <div className='text-[14px]  font-[500] mt-[16px] text-[#2D2E34] justify-center items-center flex-1'>
                        <div className="title">Jumlah uang yang telah diterima</div>
                    </div>
                    <div className='flex-1'>
                        <table className='table-cash-payment'>
                            <tbody>
                                { listTunai.map((post, index) => {
                                    return(
                                        <tr key={index} className={`${index+1 === listTunai.length && 'last-child'}`}>
                                            <td className='text-right'>{index+1}.</td>
                                            <td>Terima {index+1}</td>                 
                                            <td>{post.time}</td>                 
                                            <td className='text-right'>Rp{post.nominal.toLocaleString()}</td>                                    
                                        </tr>
                                    )
                                })}
                                <tr className='bold first-footer'>
                                    <td className='text-right' colSpan={3}>Total diterima</td>
                                    <td className='text-right'>Rp{totalTunai > 0 && totalTunai.toLocaleString()}</td>
                                </tr>
                                <tr className='bold'>
                                    <td className='text-right' colSpan={3}>Total Invoice</td>                                
                                    <td className='text-right'>Rp{customerDetail?.total?.toLocaleString()}</td>
                                </tr>
                                <tr className='bold'>
                                    <td className='text-right' colSpan={3}>{ parseInt(customerDetail?.total) > parseInt(totalTunai) ? 'Kurang Bayar' : 'Lebih Bayar' }</td>
                                    <td className='text-right'>Rp{ parseInt(customerDetail?.total) > parseInt(totalTunai) ? (parseInt(customerDetail?.total) - parseInt(totalTunai)).toLocaleString() : (parseInt(totalTunai) - parseInt(customerDetail?.total)).toLocaleString()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='border-t-2 mt-5 flex-1'>
                        <div className='text-[14px]  font-[500] mt-[16px] text-[#2D2E34]'>Petunjuk</div>
                        <div className='bg-[#eff2f6] px-8 py-4 mt-3'>
                            <ul className='list-decimal text-[14px]'>																
                                <li>Masukan uang kertas Rupiah pada Mesin setelah text di kiri atas berubah menjadi Ready</li>
                                <li>Pecahan yang dapat diterima 100.000, 50.000, 20.000, 10.000, 5.000, 2.000 dan 1000 Rupiah</li>
                                <li>Masukan jumlah uang melebihi nilai Invoice agar invoice berstatus Lunas</li>
                                <li>Ambil struk untuk ditukarkan tukarkan kembalian kepada kasir</li>
                            </ul>
                        </div>
                    </div>
                    <button
                        className="
                            text-sm text-white bg-[#5e5e5e] 
                            active:bg-blue-600 
                            px-10 py-4
                            rounded-md
                            shadow 
                            outline-none
                            disabled:opacity-60
                            mt-10
                        "
                        onClick={handleCancel}
                    >Kembali</button>
                </div>
            </Fragment>            
        </div>
    )
}
CashPaymentComp.propTypes = {
    ictReady : PropTypes.bool.isRequired,
    handleOpenMachine : PropTypes.func.isRequired,
    handleCancel : PropTypes.func.isRequired,
    listTunai : PropTypes.array.isRequired,
    totalTunai : PropTypes.number.isRequired,
    customerDetail : PropTypes.object.isRequired,
    isPaid : PropTypes.bool,
}

CashPaymentComp.defaultProps = {
    isPaid : false
}
export default CashPaymentComp;