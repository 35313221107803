import { Fragment } from "react";
import {usePaymentEdcBcaFunction} from "./paymentEdcBcaFunction";
import AlertComp from "../../component/alert/AlertComp";
import IsPaidComp from "../Home/Component/IsPaidComp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import '../../wave.css'

const WaveSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         viewBox="0 24 150 28" preserveAspectRatio="none">
        <defs>
            <path id="gentle-wave"
                  d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g className="wavies">
            <use xlinkHref="#gentle-wave" x="50" y="0" fill="#d5d5d5" />
            <use xlinkHref="#gentle-wave" x="50" y="3" fill="#e5e5e5" />
            <use xlinkHref="#gentle-wave" x="50" y="6" fill="white" />
        </g>
    </svg>
);

const PaymentEdcBcaPage = () => {

    const { isLoading, isPaid, alertValue, customerDetail, componentRef, handleCloseAlert, handleCancel, printThePage} = usePaymentEdcBcaFunction()
    return (
        <Fragment>
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
            />
            {isLoading && <div>Isloading</div>}
            <div className="col-span-1 h-screen overflow-y-auto bg-[#f2f2f2]">
                {isPaid && (
                    <IsPaidComp
                        printThePage={printThePage}
                        handleCancel={handleCancel}
                        componentRef={componentRef}
                        customerDetail={customerDetail}
                    />
                )}
                {!isPaid && (
                    <div className="flex flex-col h-full bg-white overflow-y-auto pb-28">
                        <Fragment>
                            <div className="flex flex-col h-full w-full justify-center items-center mt-10">
                                <div className="mt-5 flex flex-col items-center">
                                    <div className="text-[24px] font-[1000] mt-[16px] text-[#2D2E34]">PETUNJUK
                                        PEMBAYARAN
                                    </div>
                                    <div className="bg-[#eff2f6] px-20 py-4 mt-3 w-full">
                                        <ul className="list-decimal text-[25px]">
                                            <li>Tekan tombol Kuning pada mesin EDC</li>
                                            <li>Masukkan / Gesek kartu pada mesin EDC</li>
                                            <li>Masukkan PIN pada mesin EDC, Tekan tombol Hijau</li>
                                            <li>Tunggu struk pembayaran keluar dari mesin EDC</li>
                                            <li>Transaksi selesai</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                        <WaveSvg/>
                    </div>
                )}
            </div>
        </Fragment>
    )
}
export default PaymentEdcBcaPage