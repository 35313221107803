import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { UserContext } from "../../context/UserContext"
import api from "../../api";
import config from "../../config";
import { pageObj } from "../../generalFunction/pageObj";
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from "react-router";

export function useDaftarTransaksi(){
    const myState = useContext(UserContext);
    const [showVideo, setShowVideo] = myState.showVideo
    const [view, setView] = useState('password')
    const [isLoading, setIsLoading] = useState(false);
    const [pin, setPin] = useState({value : '', showError : false})
    const [isLogin, setIsLogin] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const componentRef = useRef();
    const navigate = useNavigate();
    const [modalValue, setModalValue] = useState({
        show: false,
        title : '',
        type : ''
    })
    const [listData, setListData] = useState({
        data : [],
        obj : [],
        offset : 0,
        lastPage : 1,
        page : 1,
        search : '',
        totalRecord : 0,
    });
    const [alertValue, setAlertValue] = useState({
        show: false,
        color: 'danger',
        text: '',
    });

    useEffect(() => {        
        setShowVideo(false)        
        // eslint-disable-next-line
    },[])

    const handleChangePin = (e) => {
        const {  value } = e.target;
        setPin(pin => ({...pin, value : value}))
    }

    const handleBlurPin = (e) => {
        const { value } = e.target;
        if (value === ''){
            setPin(pin => ({...pin, showError : true}))
        }else{
            setPin(pin => ({...pin, showError : false}))
        }
    }

    const handleSubmitPassword = async() => {
        setIsLoading(true);
        try{
            let tmp = {
                pin : pin.value
            }
            let newForm = new FormData()
            newForm.append('formData', JSON.stringify(tmp))
            
            let res = await api.post(`${config.endPoint.loginPinScreen}`, newForm).then(res => res.data);
            if (res){
                setIsLogin(true);
                fetchData();
            }
        }catch(error){
            let { response } = error;
            let msg = response?.data?.message || 'Kami sedang alami gangguan'
            
            setAlertValue(alertValue => ({...alertValue, show : true, text : msg, color: 'danger'}))
            setTimeout(() => {
                handleCloseAlert();
            }, config.timeOutValue)            
        }
    }

    const handleKeyDownPin = (e) => {
        if (e.key === 'Enter'){
            handleSubmitPassword();
        }
    }

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({...alertValue, show: false}))
    }

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            let params = {
                offset : offset,
                limit : limit
            }
            if (q){
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.paymentGroup}`, { params : params}).then(res => res.data);
            if (res){
                setListData(listData => ({...listData,
                    data : res.results,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit)),
                    totalRecord : res.count
                }))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let { response } = error;
            let msg = response?.data?.message || 'Kami sedang alami gangguan'
            
            setAlertValue(alertValue => ({...alertValue, show : true, text : msg, color: 'danger'}))
            setTimeout(() => {
                handleCloseAlert();
            }, config.timeOutValue)            
        }
    },[])

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        setListData(listData => ({...listData, search : value}))
    }

    const handleSearch = () => {
        setListData(listData => ({...listData, offset: 0, page : 1}));
        fetchData(listData.search, 0, config.itemPerPage)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handleDetail = (post) => {
        setSelectedInvoice(post);
        setModalValue(modalValue => ({...modalValue,
            show: true, 
            title : 'Detail',
            type : 'detail'
        }))
    }

    const handleCloseModal = () => {
        setModalValue(modalValue => ({...modalValue, show: false}))
    }

    const printThePage = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrint = (post) => {        
        setSelectedInvoice(post);
        setTimeout(() => {
            printThePage();
        }, 1000)
    }

    const handleKembali = () => {
        navigate('/')
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setListData(listData => ({...listData, page : page, offset: myOffset}));
        fetchData(listData.search, myOffset, config.itemPerPage);
    }

    return {
        showVideo, view, handleChangePin, handleBlurPin, pin, handleSubmitPassword, isLogin, alertValue, setView,
        isLoading, listData, handleChangeSearch, handleSearch, handleKeyDownSearch, handleDetail, handleKeyDownPin,
        selectedInvoice, handleCloseModal, modalValue, componentRef, printThePage, handlePrint, handleKembali,
        handlePaginate
    }
}