import '../../custom.css';
import { useMyHome } from './homeFunction';
import AlertComp from '../../component/alert/AlertComp';



const HomePage = () => {		
	const {		
		developmentMode, alertValue, handleCloseAlert,		
	} = useMyHome();	
	
	return (
		<div> 
			<AlertComp
				text={alertValue.text}
				color={alertValue.color}
				isOpen={alertValue.show}
				onClose={handleCloseAlert}
			/>
			{developmentMode &&
				<div
					className="label-development"
				>
					Development
				</div> 
			}
		</div>			
	);	
}
export default HomePage;
// eslint-disable-next-line