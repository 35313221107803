import { Fragment } from "react"
import PropTypes from 'prop-types';
import { PrintPage } from "../../Print/PrintPage";
const IsPaidComp = (props) => {
    const { printThePage, handleCancel, componentRef, customerDetail } = props;
    return(
        <Fragment>
            <div className='hidden'>
                <div className="grid grid-cols-1 container-home ">

                    <PrintPage ref={componentRef} customerDetail={customerDetail}/>

                </div>
            </div>
            <div className="flex flex-col bg-white h-full justify-center items-center">
                <div className="cash text-center">
                    <div className='imglunas'>
                        <img src={require('../../../assets/icon/lunas.png')} alt='icon' className='w-[400px] text-center' />
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4 w-full px-[20%] mt-5">
                    <button
                        className="
                            flex-1
                            text-sm text-white bg-[#002a6e] 
                            active:bg-blue-600 
                            px-3 py-4
                            rounded-md
                            shadow 
                            outline-none
                            disabled:opacity-60
                            "
                        onClick={printThePage}
                    >Cetak</button>
                    <button
                        className="
                            flex-1
                            text-sm text-white bg-[#5e5e5e] 
                            active:bg-blue-600 
                            px-3 py-4
                            rounded-md
                            shadow 
                            outline-none
                            disabled:opacity-60
                            "
                        onClick={handleCancel}
                    >Kembali</button>

                </div>
            </div>
        </Fragment>
    )
}

IsPaidComp.propTypes = {
    printThePage : PropTypes.func.isRequired,
    handleCancel : PropTypes.func.isRequired,
    componentRef : PropTypes.any,
    customerDetail: PropTypes.any,
}

export default IsPaidComp;