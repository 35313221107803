// import { useRoutes } from 'react-router-dom'
// import DefaultLayout from "../component/DefaultLayout";
import AuthPage from "../views/Auth/AuthPage";

import LogoutPage from '../views/Auth/LogoutPage';
import { PrintPage } from '../views/Print/PrintPage';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { menu } from '../contants/menu';
import Layout from '../component/Layout/Layout';

const Router = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					{menu.map((item, index) => {	
                        return(
                            <Route key={index} path={item.path} element={item.element} />
                        )					
                    })}
				</Route>
				<Route path="/login" element={<AuthPage />} />
				<Route path="/logout" element={<LogoutPage />} />
				<Route path="/cetak" element={<PrintPage />} />
				<Route path="*" element={<h1>404</h1>} />
			</Routes>
		</BrowserRouter>
	);
};

export default Router;

// const MainRoutes = {
//     path: '/',
//     element: <DefaultLayout />,
//     children: [
//         {
//             path: '/',
//             element: <HomePage />,
//             exact : true,
//         },        
//         {
//             path: '/logout',
//             element: <LogoutPage />,
//             exact : true,
//         },        
//         {
//             path: '/cetak',
//             element: <PrintPage />,
//             exact : true,
//         },        
//     ]
// }

// const authRoutes = {
//     path : '/login',
//     element : <AuthPage/>,    
// }
// export default function ThemeRoutes() {
//     return useRoutes([MainRoutes, authRoutes]);
// }