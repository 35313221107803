import { Fragment } from "react"
import PropTypes from 'prop-types';

const ListVaComp = (props) => {
    const { listVa, handleSubmitByVa, handleCancel, isLoading } = props;
    return(
        <Fragment>
            <div className='flex  flex-col md:container md:px-[200px] border p-10 rounded-md shadow-md bg-white h-full justify-center'>
                <div className='mb-4 font-bold'>Pilih Virtual Account</div>
                <ul className=''>
                    {listVa.map((post, index) => {
                        return (
                            <li key={index} className='cursor-pointer w-full border-b border-gray-600 py-8' onClick={() => !isLoading && handleSubmitByVa(post)}>
                                <div className='flex flex-row'>
                                    <div className='w-[80px]'><img src={post.logo} alt='bank_logo' /></div>
                                    <div className='ml-12  w-[200px]'>{post.nama}</div>
                                    { isLoading && 
                                        <div
                                            className="mr-5 inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                            role="status">
                                            <span
                                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                            >Loading...</span>
                                        </div>
                                    }
                                </div>
                            </li>
                        )
                    })}
                </ul>
                <button
                    className="
                        text-sm text-white bg-[#5e5e5e] 
                        active:bg-blue-600 
                        px-10 py-4
                        rounded-md
                        shadow 
                        outline-none
                        disabled:opacity-60
                        mt-10
                    "
                    onClick={handleCancel}
                >Kembali</button>
            </div>
        </Fragment>
    )
}

ListVaComp.propTypes = {
    listVa : PropTypes.array.isRequired,
    handleSubmitByVa : PropTypes.func.isRequired,
    handleCancel : PropTypes.func.isRequired,    
    isLoading : PropTypes.bool,    
}

export default ListVaComp;