import PropTypes from 'prop-types';

const CusDetailComp = (props) => {
    const { customerDetail }  = props;
    return(
        <div className='w-full h-full flex flex-col px-5 bg-white border-l border-l-gray-200'>
            <div className='detail-invoice  px-5 py-3'>
                <div className='invoice-title'>Invoice</div>
                <div className="grid grid-cols-2 gap-4">
                    <div>Pelanggan</div>
                    <div>: {customerDetail.konsumen_name}</div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <div>No Invoice</div>
                    <div>: {customerDetail.noinvoice}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 font-bold total-invoice">
                    <div>Total Invoice</div>
                    <div>: Rp.{customerDetail.total && customerDetail.total.toLocaleString()}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 font-bold total-invoice">
                    <div>Status</div>
                    <div>: {customerDetail.is_paid ? 'Sudah dibayar' : 'Belum dibayar'}</div>
                </div>										
                <div className="grid grid-cols-2 gap-4 font-bold total-invoice">
                    <div>Total Invoice</div>
                    <div>: Rp.{customerDetail.total && customerDetail.total.toLocaleString()}</div>
                </div>
                <div className='font-medium mt-3 mb-1'>Detail Invoice </div>
                <div>
                    {customerDetail.list_invoice?.map((post, index) => {
                        return (
                            <div className="w-full shadow-md px-5 py-3 mb-2 rounded-md" key={index}>
                                <div className='flex flex-row justify-between font-bold'>
                                    <div>{post.noinvoice_odoo}</div>
                                    <div>{post.total.toLocaleString()}</div>
                                </div>
                                <div className='text-[11px] text-gray-400'>
                                    {post.nowo_odoo}
                                </div>

                                <div className='mt-3 font-bold'>Detail</div>
                                { post.detail.map((detail, indexDetail) => {
                                    return(
                                        <div className='flex flex-row gap-2' key={indexDetail}>
                                            <div key={indexDetail}>
                                                - {detail.produk_name} 
                                            </div>
                                            <div>{detail.qty} x Rp.{detail.harga.toLocaleString()}</div>
                                            <div> = Rp.{(parseInt(detail.qty) * parseInt(detail.harga)).toLocaleString()}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

CusDetailComp.propTypes = {
    customerDetail : PropTypes.object.isRequired
}

export default CusDetailComp;