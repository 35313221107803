import { Fragment } from 'react';
import PropTypes from 'prop-types';

const FormInputInvoice = (props) => {
    const { formInput, inputRef, handleKeyDownInput, handleClear, handleChange } = props;
    return(
        <Fragment>
            <div className='text-justify'>Masukan nomor HP anda</div>
            <div className='flex flex-row relative'>
                <input
                    value={formInput}
                    className="text-sm w-full p-2  border-b-2 border-gray-400 outline-none opacity-50 focus:border-blue-400 mt-5"
                    onChange={(e) => handleChange(e)}
                    autoFocus
                    ref={inputRef}
                    onKeyDown={(e) => handleKeyDownInput(e, e.target.value)}
                />
                <div className='self-end absolute right-0 bottom-5 cursor-pointer' onClick={handleClear}>
                    <img src={require('../../../assets/icon/times-icon.png')} alt='icon' className='w-3 h-3' />
                </div>
            </div>
        </Fragment>
    )
}

FormInputInvoice.propTypes = {
    formInput : PropTypes.string,
    inputRef : PropTypes.oneOfType([
        // Either a function
        PropTypes.func, 
        // Or the instance of a DOM native element (see the note about SSR)
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]).isRequired,
    handleChange : PropTypes.func.isRequired,
    handleKeyDownInput : PropTypes.func.isRequired,
}

export default FormInputInvoice;