import PropTypes from 'prop-types';
import { Fragment } from 'react';

const PagePagination = (props) => {
    const { data, lastPage, handlePagination, page } = props;
    return (
        <Fragment>
            { data.length > 1 &&
                <div className='flex flex-row pagination'>
                    { page > 6 && 
                        <div className='py-2 px-3 border border-gray-400 cursor-pointer' onClick={() => handlePagination(1)}>{`<<`}</div>
                    }
                    { data.map((post, index) => {
                        return (                            
                            <div key={index} className={`${post === page ? 'active' : ''} py-2 px-3 border border-gray-400 cursor-pointer`} onClick={() => handlePagination(post)}>{post}</div>
                            )
                        })}
                    { lastPage !== page &&                        
                        <div className='py-2 px-3 border border-gray-400 cursor-pointer' onClick={() => handlePagination(lastPage)}>{`>>`}</div>
                    }
                </div>
            }
        </Fragment>
    )
}

PagePagination.propTypes = {
    data : PropTypes.array,
    handlePagination: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    lastPage: PropTypes.number.isRequired,
}

PagePagination.defaultProps = {
    data : [],
}

export default PagePagination;