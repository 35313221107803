import { Fragment } from "react"
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import PaymentChannelComp from "./PaymentChannelComp";

const PaymentMethodComp = (props) => {
    const { isPaid, usingVa, selectedPaymentMethod, handleCancel, handleNonTunai, handleTunai, isLoading, isAvalable, alertValue, handleEDCBCA, handleNonEDC } = props;
    return (
        <Fragment>
            { isLoading && 
                <div className='absolute  mx-auto my-auto'>
                    <ReactLoading type={'bars'} color="#2c6ff5" />
                </div>
            }
            <div className="flex flex-col bg-white h-full justify-center items-center">
                { isAvalable ?
                    <>
                        {!isPaid &&
                            <>
                                {selectedPaymentMethod == null ?
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="cash text-center cursor-pointer" onClick={handleTunai}>
                                            <img src={require('../../../assets/icon/bill-acceptor.png')} alt='icon'
                                                 className='h-[200px] w-auto text-center'/>
                                            <div className='mt-5 font-semibold text-[18px]'>Bayar Tunai</div>
                                        </div>
                                        <div className="cassless text-center ml-10 cursor-pointer"
                                             onClick={handleNonTunai}>
                                            <img src={require('../../../assets/icon/cashless.png')} alt='icon'
                                                 className='h-[200px]'/>
                                            <div className='mt-5 font-semibold text-[18px]'>Bayar Non Tunai</div>

                                        </div>
                                    </div>
                                    :
                                    <PaymentChannelComp usingVa={ usingVa } handleEDCBCA={ handleEDCBCA } handleNonEDC={ handleNonEDC }/>
                                }


                                <button
                                    className="
                                        text-sm text-white bg-[#5e5e5e] 
                                        active:bg-blue-600 
                                        px-10 py-4
                                        rounded-md
                                        shadow 
                                        outline-none
                                        disabled:opacity-60
                                        mt-10
                                    "
                                    onClick={handleCancel}
                                >Kembali
                                </button>
                            </>
                        }
                    </>
                    :
                    <div className='w-full flex flex-col justify-center items-center'>
                        <div className='text-[16px] bg-red-100 text-red-600 px-7 py-2 rounded'>{ alertValue.text }</div>
                        <button
                            className="
                                text-sm text-white bg-[#5e5e5e] 
                                active:bg-blue-600 
                                px-10 py-4
                                rounded-md
                                shadow 
                                outline-none
                                disabled:opacity-60
                                mt-10
                            "
                            onClick={handleCancel}
                        >Kembali</button>
                    </div>
                }
                
            </div>
        </Fragment>
    )
}

PaymentMethodComp.propTypes = {
    isPaid: PropTypes.bool,
    usingVA: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    printThePage: PropTypes.func,
    handleCancel : PropTypes.func,
    handleNonTunai : PropTypes.func,
    handleTunai : PropTypes.func,
    isAvalable : PropTypes.bool,
    handleVirtualAccount: PropTypes.func,
    handleEDCBCA: PropTypes.func,
    handleQris: PropTypes.func,
}

PaymentMethodComp.defaultProps = {
    isPaid : false,
    isAvalable : true,
}

export default PaymentMethodComp;