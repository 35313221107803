import AlertComp from "../../component/alert/AlertComp";
import IsPaidComp from "../Home/Component/IsPaidComp";
import PaymentMethodComp from "../Home/Component/PaymentMethodComp";
// import { PrintPage } from "../Print/PrintPage";
import { useMethodPayment } from "./methodPaymentFunction"


const MethodPaymentPage = (props) => {
    const { isLoading, handleCancel, usingVa, customerDetail, selectedPaymentMethod,
        handleNonTunai, handleTunai,
        // componentRef, 
        printThePage, alertValue, handleCloseAlert, isAvailable,
        handleEDCBCA, handleNonEDC
    } = useMethodPayment();
    return(
        <div className="col-start-1 col-end-5 h-screen overflow-y-auto bg-[#f2f2f2]">
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
            />
            { isLoading && <div>Isloading</div>}
            <div className='hidden'>
                {/* <PrintPage ref={componentRef} customerDetail={customerDetail}/> */}
            </div>
            { customerDetail?.is_paid &&
                <IsPaidComp
                    handleCancel={handleCancel}
                    printThePage={printThePage}
                />
            }
            { !customerDetail?.is_paid &&            
                <PaymentMethodComp
                    isPaid={customerDetail && customerDetail.is_paid}
                    usingVa={usingVa}
                    selectedPaymentMethod={selectedPaymentMethod}
                    // printThePage={printThePage}
                    handleCancel={handleCancel}
                    handleNonTunai={handleNonTunai}
                    handleTunai={handleTunai}		
                    isLoading={isLoading}	
                    isAvalable={isAvailable}
                    alertValue={alertValue}
                    handleEDCBCA={handleEDCBCA}
                    handleNonEDC={handleNonEDC}
                />
            }
        </div>
    )
}
export default MethodPaymentPage