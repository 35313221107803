import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useGlobalFunction } from "../../generalFunction/generalFunction";
import api from "../../api";
import config from "../../config";

export function useVideoScreen() {
    const myState = useContext(UserContext);
    const [showVideo] = myState.showVideo;
    const [videoLoop] = useState([
        'https://vendingmachinenode.myappscenter.com/video/?tagId=EuVsF57tC6e0Tq7Hk8XpoQ==',
    ]);
    const videoRef = useRef(null);
    const [videoIndex, setVidIndex] = useState(0);

    const handleNextVideo = () => {
        if (videoIndex === (videoLoop.length - 1)) {
            setVidIndex(0)
        } else {
            setVidIndex(videoIndex + 1)
        }
        if (videoRef.current) {
            setTimeout(() => {
                videoRef.current.play();
            }, 200);
        }
    };

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, [videoIndex]);

    return {
        showVideo,
        videoLoop,
        videoRef,
        handleNextVideo,
        videoIndex
    };
}