import { useState } from "react";
import { useGlobalFunction } from "../../generalFunction/generalFunction";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import api from "../../api";
import config from "../../config";

export function useAuth(props){
    const navigate = useNavigate()
    const { isLoading, setIsLoading } = useGlobalFunction();
    const [cookies, setCookie] = useCookies([config.cookiesName]);
    const [alertValue, setAlertValue] = useState({
        show: false,
        text : 'wrong username or password',
        color : 'danger'
    })
    const [formData, setFormData] = useState({
        username: {
            name: 'username',
            tipe: 'text',
            value: '',
            label: 'Username',
            required: true,
            showError: false,
        },
        password: {
            name: 'password',
            tipe: 'password',
            value: '',
            label: 'Password',
            required: true,
            showError: false,
            isShow: false
        }
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({
            ...formData,
            [name]: { ...formData[name], value: value }
        }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === '') {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: true }
            }))
        } else {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: false }
            }))
        }
    }

    const handleShowPassword = () => {
        setFormData(formData => ({...formData,
            password : {...formData.password, isShow : !formData.password.isShow}
        }))
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let countError = 0;
            let tmp = {}
            let obj = {...formData};
            
            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    post['showError'] = true;
                    countError++;                    
                }else{
                    tmp[index] = post.value;
                }
                return true;
            })
                        
            if (countError > 0){
                setIsLoading(false);
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp))

            const res = await api.post(`${config.endPoint.login}`, newForm).then(res => res.data);
            if (res){
                let tmpcookie = {
                    'token' : res.token, 
                    'development' : res.development
                    // user_info : res.data.user_info
                };
			    setCookie(config.cookiesName, tmpcookie, { path: '/', maxAge: config.expiresSession });
                return navigate('/');    
            }            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }

            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, config.timeOutValue)
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter'){
            handleSubmit()
        }
    }

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({...alertValue, show: false}))
    }

    return {
        isLoading, setIsLoading, formData, cookies, handleChange, handleSubmit, handleShowPassword, handleBlur,
        handleKeyDown, alertValue, handleCloseAlert
    }
}