import { Fragment } from "react"
import PropTypes from 'prop-types';
import Moment from 'moment';
const VaSelectedComp = (props) => {
    const { 
        customerDetail,
        selectedVa,
        companyCode,
        vaNumber,        
        expTime,
        handleCancel,
        handleCancelPayment,
    } = props;
    return(
        <div className="flex flex-col h-[100%] w-full bg-white px-[20px]">
            <div className="flex flex-row justify-center">
                <div className='flex  flex-col mt-20 border rounded-md shadow-md bg-white pb-10'>
                    <div className="bg-[#999] p-5 relative h-[120px]">
                        <div className='text-white font-bold'>PT. Asia Surya Perkasa</div>
                    </div>
                    <div className='relative p-5'>
                        <div className='absolute top-[-40px] bg-white p-5 shadow-md rounded-md  left-0  ml-[20px] mr-[20px] w-[calc(100%_-_40px)]'>
                            <div className="flex flex-col">
                                <div className="flex flex-row justify-between">
                                    <div className='font-[500] text-[#4F515C] text-[12px]'>Total</div>
                                    <div className='font-[500] text-[#4F515C] text-[12px]'>Bayar Sebelum <span className='text-[#3E62FE]'>{ expTime && Moment(Moment(expTime, 'YYYY-MM-DD HH:mm:ss').toDate()).format('DD-MM-YYYY HH:mm') }</span></div>
                                </div>
                                <div className='font-extrabold text-[21px] text-[#2D2E34]'>Rp{customerDetail.total.toLocaleString().replace(/,/g, '.')}</div>
                            </div>
                        </div>
                        <div className='mt-[60px] flex flex-row justify-between'>
                            { selectedVa && selectedVa.script_kode === 'bca' &&
                                <div className='text-[16px] text-[#2D2E34] font-[700]'>Bank BCA</div>
                            }
                            { selectedVa && selectedVa.script_kode === 'mandiri' &&
                                <div className='text-[16px] text-[#2D2E34] font-[700]'>Bank Mandiri</div>
                            }
                            { selectedVa && selectedVa.script_kode === 'bni' &&
                                <div className='text-[16px] text-[#2D2E34] font-[700]'>Bank BNI</div>
                            }
                        </div>
                        <div className='text-[14px] mt-[14px]'>
                            Selesaikan pembayaran anda dengan memasukan nomor virtual account dibawah ini
                        </div>
                        { selectedVa && selectedVa.script_kode === 'mandiri' &&
                            <>
                                <div className='text-[14px] mt-[16px] text-[#2D2E34]'>
                                    Kode Perusahaan
                                </div>
                                <div className='text-[16px] text-[#2D2E34] font-[500] border-b-2 pb-1'>
                                    {companyCode}
                                </div>
                            </>
                        }
                        <div className='text-[14px] mt-[16px] text-[#2D2E34]'>
                            Nomor Virtual Account
                        </div>
                        <div className='text-[16px] text-[#2D2E34] font-[500] border-b-2 pb-1'>
                            {vaNumber}
                        </div>
                        
                    </div>
                </div>
                <div>
                <div className='flex  flex-col mt-20 border rounded-md shadow-md bg-white w-[420px] ml-4 p-5'>
                    <div className='text-[14px]  font-[500] mt-[16px] text-[#1F44E5]'>
                        Cara Pembayaran
                        </div>
                        {selectedVa && selectedVa.script_kode === 'bca' &&
                            <div className='relative'>                               
                                <div className='border-t-2 mt-5'>
                                    <div className='text-[14px]  font-[500] mt-[16px] text-[#2D2E34]'>Klik BCA</div>
                                    <div className='bg-[#eff2f6] px-8 py-4 mt-3'>
                                        <ul className='list-decimal text-[14px]'>
                                            <li>Pilih menu transaksi lainnya  > Transfer > ke Rekening BCA Virtual Account</li>
                                            <li>Masukan nomor virtual account</li>
                                            <li>Masukan Jumlah sesuai dengan tagihan invoice </li>
                                            <li>Pembayaran Selesai </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='border-t-2 mt-5'>
                                    <div className='text-[14px]  font-[500] mt-[16px] text-[#2D2E34]'>m-BCA</div>
                                    <div className='bg-[#eff2f6] px-8 py-4 mt-3'>
                                        <ul className='list-decimal text-[14px]'>
                                            <li>Pilih m-Transfer > BCA Virtual Account</li>
                                            <li>Masukan nomor virtual account</li>
                                            <li>Masukan Jumlah sesuai dengan tagihan invoice </li>
                                            <li>Pembayaran Selesai </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {selectedVa && selectedVa.script_kode === 'mandiri' &&
                            // <div className='relative overflow-y-scroll h-[calc(100%_+_159px)]'>
                            <div className='relative'>
                                <div className='border-t-2 mt-5'>
                                    <div className='text-[14px]  font-[500] mt-[16px] text-[#2D2E34]'>Internet Banking</div>
                                    <div className='bg-[#eff2f6] px-8 py-4 mt-3'>
                                        <ul className='list-decimal text-[14px]'>																
                                            <li>Pilih menu Pembayaran, kemudian Multi Payment, dan pilih Penyedia Jasa Midtrans</li>
                                            <li>Masukan nomor virtual account</li>
                                            <li>Pembayaran Selesai </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {selectedVa && selectedVa.script_kode === 'bni' &&
                            <div className='relative'>
                                {/* <div>
                                    <div className='text-[14px]  font-[500] mt-[16px] text-[#2D2E34]'>ATM BNI</div>
                                    <div className='bg-[#eff2f6] px-8 py-4 mt-3'>
                                        <ul className='list-decimal text-[14px]'>																
                                            <li>Pilih menu Lainnya</li>
                                            <li>Pilih transfer</li>
                                            <li>Pilih BNI Akun</li>																
                                            <li>Masukkan No. VA </li>
                                            <li>Masukan Jumlah sesuai dengan tagihan invoice kemudian konfirmasi </li>
                                            <li>Pembayaran Selesai </li>
                                        </ul>
                                    </div>
                                </div> */}
                                <div className='border-t-2 mt-5'>
                                    <div className='text-[14px]  font-[500] mt-[16px] text-[#2D2E34]'>iBank Personal</div>
                                    <div className='bg-[#eff2f6] px-8 py-4 mt-3'>
                                        <ul className='list-decimal text-[14px]'>																
                                            <li>Klik menu Transfer, lalu pilih Virtual Account Billing.</li>
                                            <li>Masukan nomor virtual account</li>
                                            <li>Lalu pilih rekening debet yang akan digunakan. Kemudian tekan Lanjut.</li>
                                            <li>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.</li>
                                            <li>Masukkan Kode Otentikasi Token.</li>
                                            <li>Anda akan menerima notifikasi bahwa transaksi berhasil.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='border-t-2 mt-5'>
                                    <div className='text-[14px]  font-[500] mt-[16px] text-[#2D2E34]'>Mobile Banking</div>
                                    <div className='bg-[#eff2f6] px-8 py-4 mt-3'>
                                        <ul className='list-decimal text-[14px]'>																
                                            <li>Pilih menu Transfer.</li>
                                            <li>Pilih menu Virtual Account Billing, lalu pilih rekening debet.</li>
                                            <li>Masukkan nomor Virtual Account</li>
                                            <li>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.</li>
                                            <li>Konfirmasi transaksi dan masukkan Password Transaksi.</li>
                                            <li>Anda akan menerima notifikasi bahwa transaksi berhasil.</li>																
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-row'>
                { !customerDetail.is_paid &&
                    <button
                        className="
                            w-full
                            text-sm text-white bg-[#002a6e] 
                            active:bg-blue-600 
                            px-3 py-4
                            rounded-md
                            shadow 
                            outline-none
                            disabled:opacity-60
                            mt-10
                            mr-4
                        "
                        onClick={handleCancelPayment}
                    >Pilih Metode Pembayaran Lain</button>
                }										
                <button
                    className="
                        w-full
                        text-sm text-white bg-[#5e5e5e] 
                        active:bg-blue-600 
                        px-10 py-4
                        rounded-md
                        shadow 
                        outline-none
                        disabled:opacity-60
                        mt-10
                    "
                    onClick={handleCancel}
                >Kembali</button>
            </div>
        </div>
    )
}

VaSelectedComp.propTypes = {
    customerDetail : PropTypes.object.isRequired,
    selectedVa : PropTypes.object.isRequired,
    companyCode : PropTypes.string,
    vaNumber : PropTypes.string.isRequired,
    expTime : PropTypes.any,
    handleCancel : PropTypes.func.isRequired,
    handleCancelPayment : PropTypes.func.isRequired
}
export default VaSelectedComp;