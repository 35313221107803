// import { BrowserRouter } from "react-router-dom";
import Router from "./routes";

const App = () => {
  return(
    // <BrowserRouter>      
        <Router />
    // </BrowserRouter>
  )
}
export default App;