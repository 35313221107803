import { Fragment } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from "./authFunction";
import ReactLoading from 'react-loading';

const AuthPage = (props) => {
    const { 
        isLoading,  formData,  handleChange, handleSubmit, handleShowPassword, handleBlur,
        handleKeyDown, alertValue, handleCloseAlert
    } = useAuth();

    
    return (
        <Fragment>
            <div className="">
                <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
                    { isLoading &&
                        <div className=''>              
                            <ReactLoading type={'bars'} color="#2c6ff5" />
                        </div>
                    }
                    <div className="relative w-32 h-32">
                        <div className='w-32 h-32 rounded-full bg-[#fff] absolute top-1/2'>
                            <div className='w-full m-auto'>
                                <img src={require('../../assets/turi.png')} className="mx-auto mt-4 w-3/4" alt='img' />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-2/4 md:w-3/4 sm:w-full xl:w-1/4 md:max-xl sm:text-left border border-collapse border-[#cecece] p-6 rounded-lg bg-white flex flex-col items-center pb-10">
                        <div className="w-full mt-20">
                            <div className='
                                flex 
                                flex-row 
                                bg-white 
                                items-center
                                border
                                border-gray-400
                                rounded'
                            >
                                <div className='bg-[#002a6e] py-2 px-3'>
                                    <FontAwesomeIcon icon={faUser} color="white" />
                                </div>
                                <input
                                    type="email"
                                    name={formData.username.name}
                                    id={formData.username.name}
                                    placeholder="username"
                                    className="outline-none border-transparent focus:border-transparent focus:ring-0 w-full ml-4"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                            <div className='
                                flex 
                                flex-row 
                                bg-white 
                                items-center
                                border
                                border-gray-400
                                rounded mt-4'
                            >
                                <div className='bg-[#002a6e] py-2 px-3'>
                                    <FontAwesomeIcon icon={faLock} color="white" />
                                </div>
                                <input
                                    type={formData.password.isShow ? 'text' : formData.password.tipe }
                                    name={formData.password.name}
                                    id={formData.password.name}
                                    placeholder={formData.password.label}
                                    className="outline-none border-transparent focus:border-transparent focus:ring-0 w-full ml-2"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={handleKeyDown}
                                />
                                <div className='py-2 px-3 cursor-pointer' onClick={handleShowPassword}>
                                    <FontAwesomeIcon icon={formData.password.isShow ? faEyeSlash : faEye} color="black" />
                                </div>
                            </div>
                        </div>
                        <button
                            className="mt-6 w-5/6 text-sm text-white bg-[#002a6e] active:bg-blue-600 px-3 py-2 rounded-lg shadow outline-none"
                            onClick={handleSubmit}
                        >Submit</button>
                    </div>
                    { alertValue.show &&
                        <div className={`py-3 rounded-md w-full lg:w-2/4 md:w-3/4 sm:w-full xl:w-1/4 md:max-xl bg-[rgba(255,172,172,0.8)] flex items-center text-red-800 justify-center inset-0 z-100 outline-none focus:outline-none mt-10`} style={{zIndex: 12}}>
                            <div className='grow flex justify-center'>{alertValue.text}</div>
                            <div className='flex-none px-5 cursor-pointer' onClick={handleCloseAlert}>x</div>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default AuthPage;