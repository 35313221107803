import { Fragment } from "react"
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

const QrisComp = (props) => {
    const { isLoading, qrImage, developmentMode, paymentUrl,
        handleCancelPayment, handleCancel, customerDetail
    } = props;
    return(
        <Fragment>
            <div className='flex flex-col justify-center items-center min-w-full min-h-full'>
                {isLoading &&
                    <div className='absolute  mx-auto my-auto'>
                        <ReactLoading type={'bars'} color="#2c6ff5" />
                    </div>
                }
                
                <Fragment>
                    <div className='mb-3 font-bold text-blue-500 text-xl'>Gunakan Mbanking anda, dan scan QR dibawah ini</div>
                    <img src={qrImage} alt='qrcode' />
                    {developmentMode &&
                        <div className='mt-15'>
                            <div>{paymentUrl}</div>
                        </div>
                    }
                    <div className='w-full flex flex-row px-10'>
                        { !customerDetail.is_paid &&
                            <button
                                className="
                                    w-full
                                    text-sm text-white bg-[#002a6e] 
                                    active:bg-blue-600 
                                    px-3 py-4
                                    rounded-md
                                    shadow 
                                    outline-none
                                    disabled:opacity-60
                                    mt-10
                                    mr-4
                                "
                                onClick={handleCancelPayment}
                            >Pilih Metode Pembayaran Lain</button>
                        }										
                        <button
                            className="
                                w-full
                                text-sm text-white bg-[#5e5e5e] 
                                active:bg-blue-600 
                                px-10 py-4
                                rounded-md
                                shadow 
                                outline-none
                                disabled:opacity-60
                                mt-10
                            "
                            onClick={handleCancel}
                        >Kembali</button>
                    </div>
                </Fragment>                
            </div>
            
        </Fragment>
    )
}
QrisComp.propTypes = {
    isLoading : PropTypes.bool.isRequired, 
    qrImage : PropTypes.string.isRequired, 
    developmentMode : PropTypes.bool.isRequired, 
    paymentUrl : PropTypes.string.isRequired,
    handleCancelPayment : PropTypes.func.isRequired,
    handleCancel : PropTypes.func.isRequired,
    customerDetail : PropTypes.object.isRequired
}
export default QrisComp;