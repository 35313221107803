import { Fragment } from "react";
import CashPaymentComp from "../Home/Component/CashPaymentComp";
// import { PrintPage } from "../Print/PrintPage";
import { usePaymentCash } from "./paymentCashFunction";
import IsPaidComp from "../Home/Component/IsPaidComp";
import AlertComp from "../../component/alert/AlertComp";

const PaymentCashPage = () => {
    const { ictReady, handleOpenMachine, ictText, listTunai, totalTunai, customerDetail, handleCancel,
        componentRef,
        isPaid, printThePage, isOnline,
        alertValue, handleCloseAlert
    } = usePaymentCash()
    return (
        // blah
        //     { isPaid &&
        //         <Fragment>
        //             <IsPaidComp
        //                 printThePage={printThePage}
        //                 handleCancel={handleCancel}
        //             />
        //         </Fragment>
        //     }
        //     { !isPaid &&
        <Fragment>
            
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
            />
            <div className="col-span-1 h-screen overflow-y-auto bg-[#f2f2f2]">                        
                { isPaid &&
                    <IsPaidComp
                        printThePage={printThePage}
                        handleCancel={handleCancel}
                        componentRef={componentRef}
                        customerDetail={customerDetail}
                    />
                }
                { !isPaid &&
                    <CashPaymentComp
                        isPaid={customerDetail.isPaid}
                        ictReady={ictReady}
                        handleOpenMachine={handleOpenMachine}
                        ictText={ictText}
                        listTunai={listTunai}
                        totalTunai={totalTunai}
                        customerDetail={customerDetail}
                        handleCancel={handleCancel}
                        isOnline={isOnline}
                    />
                }
            </div>
        </Fragment>
    )
}
export default PaymentCashPage;