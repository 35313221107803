import React, { Fragment } from "react"
// import Moment from 'moment'

export const PrintPage = React.forwardRef((props, ref) => {
    const { customerDetail } = props;
    
    return (
        <div className='w-[80mm] py-5 px-2 relative' ref={ref}>
            <div className="absolute top-0 right-0 w-full h-full">
                <div className='text-[65px] text-red-500 font-bold opacity-50 w-full h-full flex flex-row justify-center items-center -rotate-45'>
                    <div className={`${customerDetail?.is_paid ? 'text-[65px]' : 'text-[45px]'}`}>Lunas</div>
                </div>
            </div>
            <div className="logo px-2">
                <div className='flex flex-row items-center'>
                    { customerDetail && customerDetail.logo ?
                        <div className='w-[240px]'>                            
                            <img src={customerDetail.logo} alt='logo'/>
                        </div>
                        :
                        <div className='mb-[20px] flex flex-row items-center'>
                            <div className='w-[30px]'>
                                <img src={require('../../assets/turi.png')} alt='logo'/>
                            </div>
                            <div className='flex flex-col ml-[8px]'>
                                <div className='text-blue-600 font-bold text-[14px] tracking-wide'>PT. ASIA SURYA PERKASA</div>
                                <div className='text-[8px] border-t border-t-[blue-600]'>Main Dealer of <span className='text-red-600'>HONDA MOTORCYCLE</span> BANGKA BELITUNG</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className='text-[12px]  border-b pb-[20px]'>
                <table>
                    <tbody>
                        <tr>
                            <td className='px-2 py-[2px]'>Tanggal</td>
                            <td className='px-2 py-[2px]'>:</td>
                            <td className='px-2 py-[2px]'>{ customerDetail?.tanggal_display }</td>                
                        </tr>
                        <tr>
                            <td className='px-2 py-[2px]'>AHASS</td>
                            <td className='px-2 py-[2px]'>:</td>
                            <td className='px-2 py-[2px]'>{ customerDetail && customerDetail.ahass_name }</td>                
                        </tr>
                        <tr>
                            <td className='px-2 py-[2px]'>Nomor</td>
                            <td className='px-2 py-[2px]'>:</td>
                            <td className='px-2 py-[2px]'>{ customerDetail && customerDetail?.noinvoice }</td>                
                        </tr>
                        <tr>
                            <td className='px-2 py-[2px]'>Nama</td>
                            <td className='px-2 py-[2px]'>:</td>
                            <td className='px-2 py-[2px]'>{ customerDetail && customerDetail.konsumen_name }</td>                
                        </tr>
                        <tr>
                            <td className='px-2 py-[2px]'>Status</td>
                            <td className='px-2 py-[2px]'>:</td>
                            <td className='px-2 py-[2px]'>{ customerDetail && customerDetail.is_paid ? 'Sudah dibayar' : 'Belum dibayar'}</td>          
                        </tr>
                        <tr>
                            <td className='px-2 py-[2px]'>Metode Pembayaran</td>
                            <td className='px-2 py-[2px]'>:</td>
                            <td className='px-2 py-[2px]'>{ customerDetail && customerDetail?.payment_type_display }</td>
                        </tr>
                        <tr>
                            <td className='px-2 py-[2px]'>NOPOL</td>
                            <td className='px-2 py-[2px]'>:</td>
                            <td className='px-2 py-[2px]'>{ customerDetail && customerDetail?.nopol }</td>
                        </tr>
                    </tbody>                    
                </table>
            </div>
            <div className='mt-[20px] text-[12px] border-b pb-[20px] px-2'>
                <div className='font-[600]'>Detail</div>
                <table className='table-row' style={{display: 'table-cell'}}>
                    <tbody>
                        { customerDetail?.list_invoice.map((post, index) => {
                            return(
                                <Fragment key={index}>
                                    <tr key={index}>
                                        <td colSpan={2}>{post.noinvoice_odoo}</td>
                                    </tr>
                                    { post.detail.map((detail, detailIndex) => {
                                        return(
                                            <tr key={detailIndex}>
                                                <td className={`${detailIndex+1 === post.detail?.length && 'pb-3'}`}>{index+1+detailIndex+1}</td>
                                                <td className={`${detailIndex+1 === post.detail?.length && 'pb-3'}`}>{detail.produk_name} x {detail.qty} x {detail.harga?.toLocaleString()}</td>
                                                <td className='text-right'>{(parseInt(detail.qty) * parseInt(detail.harga)).toLocaleString("id", {
                                                        style: "currency",
                                                        currency: "IDR",
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </Fragment>
                            )
                        })}
                    </tbody>
                </table>
                {/* <div className='mt-[10px]'>
                    { customerDetail && customerDetail.detail.map((post, index) => {
                        return(
                            <div className='flex flex-row justify-between py-[3px]' key={index}>
                                <div>{post.product} x {post.qty}</div>
                                <div>{post.harga.toLocaleString().replace(/,/g, '.')}</div>
                            </div>
                        )
                    })}
                </div> */}
            </div>
            <div className='mt-[20px] text-[12px]'>
                <div className="text-right">
                    <table className='w-[100%]'>
                        <tbody>
                            <tr>
                                <td className='py-[2px] px-2 text-right'>Untaxed Amount</td>
                                <td className='py-[2px] px-2 pl-[10px] text-right'>{(customerDetail?.total * (100/111)).toLocaleString("id", {
                                                style: "currency",
                                                currency: "IDR",
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            }) }</td>
                            </tr>
                            <tr>
                                <td className='py-[2px] px-2 text-right'>Taxed</td>
                                <td className='py-[2px] px-2 pl-[10px] text-right'>{ (parseFloat(customerDetail?.total * (100/111)) * parseFloat(11 /100)).toLocaleString("id", {
                                                style: "currency",
                                                currency: "IDR",
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            })   }</td>
                            </tr>
                            <tr>
                                <td className='py-[2px] px-2 text-right'>Total</td>
                                <td className='py-[2px] px-2 pl-[10px] text-right font-bold'>{customerDetail && customerDetail.total && customerDetail.total.toLocaleString("id", {
                                                style: "currency",
                                                currency: "IDR",
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            })}</td>
                            </tr>
                            {/* { customerDetail && customerDetail.payment_detail && customerDetail.payment_detail.total_bayar  &&
                                <Fragment>
                                    <tr>
                                        <td className='py-[2px] px-2 text-right'>Total Cash</td>
                                        <td className='py-[2px] px-2 pl-[10px] text-right font-bold'>{customerDetail.payment_detail.total_bayar.toLocaleString("id", {
                                                style: "currency",
                                                currency: "IDR",
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            })}</td>
                                    </tr>
                                    { [3, '3'].includes(customerDetail.payment_type)  &&                                        
                                        <tr>
                                            <td className='py-[2px] px-2 text-right'>{ customerDetail?.payment_detail?.kembalian > 0 ? 'Kembalian' : 'Kurang Bayar'}</td>
                                            <td className='py-[2px] px-2 pl-[10px] text-right font-bold'>{customerDetail?.payment_detail?.kembalian > 0  ? parseInt(customerDetail.payment_detail.kembalian).toLocaleString("id", {
                                                style: "currency",
                                                currency: "IDR",
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            }) : (parseInt(customerDetail.payment_detail.kembalian) * -1).toLocaleString("id", {
                                                style: "currency",
                                                currency: "IDR",
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            })}</td>
                                        </tr>
                                    }
                                </Fragment>
                            } */}
                        </tbody>
                    </table>                    
                </div>
            </div>
        </div>
    )
});
