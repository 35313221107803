import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import api from "../../api";
import config from "../../config";
// import { w3cwebsocket as W3CWebSocket } from "websocket";
import { UserContext } from "../../context/UserContext";
import CusDetailComp from "../Home/Component/CusDetailComp";
import Cookies from 'universal-cookie';
import { useReactToPrint } from 'react-to-print';

export function usePaymentCash(props){
    const cookies = new Cookies();
    const [alertValue, setAlertValue] = useState({
        show: false,
        color: 'danger',
        text: '',
    });
    const myState = useContext(UserContext);    
    const [isOnline, setIsOnline] = useState(false);
    const [rightState, rightDispatch] = myState.rightState;
    const [ictReady, setIctReady] = useState(false);
    const [ictText, setIctText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [listTunai, setListTunai] = useState([]);
    const [totalTunai, setTotalTunai] = useState(0);
    let tmpCustomerDetail = sessionStorage.getItem('customerDetail') ? JSON.parse(sessionStorage.getItem('customerDetail')) : null
    const [customerDetail, setCustomerDetail] = useState(tmpCustomerDetail);
    const navigate = useNavigate();
    const [isPaid, setIsPaid] = useState(false);
    const [sisaSetoran, setSisaSetoran] = useState(0);
    const [messages, setMessages] = useState([]);
    const [token, setToken] = useState('');
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const componentRef = useRef();
    const ws = useRef(null);
    

    const handleCancel = () => {
        navigate('/')
    }

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({ ...alertValue, show: false }))
    }

    const fetchData = useCallback(async(formInput) => {        
        try{
            let newForm = new FormData();
            let tmp = {
                'noinvoice': formInput
            }
            newForm.append('formData', JSON.stringify(tmp))
            const res = await api.post(`${config.endPoint.invoiceInfo}`, newForm).then(res => res.data);
            console.log('res is >>>>', res);
            if (res){
                let tmpListTunai = [];
                let tmpTotalTunai = 0
                res.results.list_pembayaran.map(post => {
                    tmpTotalTunai += post.nominal;
                    tmpListTunai.push({'nominal' : post.nominal, 'time' : post.tanggal})
                    return true;
                })
                setListTunai(tmpListTunai);
                setTotalTunai(tmpTotalTunai)
                let tmp = {...res.results}
                tmp['logo'] = res.logo;
                tmp['ahass_name'] = res.ahass_name
                setCustomerDetail(tmp);                 
                rightDispatch({
                    type: 'OTHERS',
                    content : <CusDetailComp
                        customerDetail={res.results}
                    />
                })
            }
        }catch(error){

        }
        
    },[rightDispatch])

    useEffect(() => {
        if (rightState.type !== 'OTHERS'){            
            fetchData(sessionStorage.getItem('inputHp'))        
        }
            
        // eslint-disable-next-line
    },[fetchData])

    useEffect(() => {
        const myCookies = cookies.get(config.cookiesName);
        if (myCookies && myCookies.token) {
            setToken(myCookies.token);
            // openWs(myCookies.token)
        }
        
        // eslint-disable-next-line
    }, [])

    const handleOpenMachine = useCallback(async() => {
        setIsLoading(true);
        setIctReady(false);
        setIctText('Waiting..')
        try{
            let tmpForm = customerDetail;
            tmpForm['hp'] = sessionStorage.getItem('inputHp')
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm))
            
            const res = await api.post(`${config.endPoint.openMachine}`, newForm).then(res => res.data);
            if (res){                
                setIctReady(res.machine_ready);
                setIctText(res.message);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = 'Error reload bill acceptor';
            
            let status = error && error.response && error.response.status ? error.response.status : '';
            if (status && status === 400) {                
                msg = error.response && error.response.data && error.response && error.response.data.message ? error.response.data.message : 'Error reload bill acceptor'
            } 
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        
        }
    },[customerDetail]);

    useEffect(() => {
        handleOpenMachine();
    },[handleOpenMachine])


    // useEffect(() => {        
    //     if (isPaid){            
    //         handleRecallPayment(paymentId);            
    //         setTimeout(() => {
    //             printThePage();
    //         }, 500)
    //     }        
    //     // eslint-disable-next-line
    // },[isPaid, paymentId, handleRecallPayment])
    
    useEffect(() => {        
        if (isPaid){            
            fetchData(sessionStorage.getItem('inputHp'));            
            // setTimeout(() => {
            //     printThePage();
            // }, 500)
        }        
        // eslint-disable-next-line
    },[isPaid,  fetchData])

    const printThePage = useReactToPrint({
        content: () => componentRef.current,
    });

    const addMessage = useCallback((myMsg) =>  {
        setMessages([...messages, myMsg]); 

        try {
            let message = JSON.parse(myMsg);            
            if (message && message.text && customerDetail && message.text.id === customerDetail.id) {                         
                if (message.text.type === 'payment_cash_notification'){          
                    setListTunai([...listTunai, {'nominal' : message.text.nominal, 'time' : message.text.time}]);
                    setTotalTunai(totalTunai+parseInt(message.text.nominal))
                    setIctReady(message.text.is_connected);
                    setIctText(message.text.state);
                    setSisaSetoran(parseInt(customerDetail.total) - (totalTunai+parseInt(message.text.nominal)))

                    
                    
                    if (message.text.is_paid){
                        setIsPaid(true);
                    //     setLeftScreenType('is_paid');                        
                    //     setShowVa(false);                    
                    //     let timer = setTimeout(() => {
                    //         setFormInput('');
                    //         setShowQr(false);
                    //         setIsPaid(false);
                    //         setShowVideo(true);
                    //         setShowInvoice(false);
                    //         handleCancel();                            
                    //     }, 6000)
        
                    //     return () => {
                    //         clearTimeout(timer);
                    //     };
                    }                    
                }
            }
            if (message && message.text){
                if (message.text.type === 'machine_ready'){
                    setIctReady(message.text.is_ready);
                    setIctText(message.text.ict_text);
                }
            }
        } catch (e) {            
            return false;
        }
        return true;
        // eslint-disable-next-line
    },[customerDetail, listTunai])



    useEffect(() => {
        if (token){        
            if (waitingToReconnect) {
                return;
            }

            // Only set up the websocket once
            if (!ws.current) {
                // const client = new W3CWebSocket(config.apiBaseUrl.wsServer + token + '/');
                // const client = new W3CWebSocket(config.apiBaseUrl.wsServer + token + '/');
                const client = new WebSocket(config.apiBaseUrl.wsServer + token + '/', 'echo-protocol');
                ws.current = client;

                window.client = client;

                client.onerror = (e) => console.error(e);

                client.onopen = () => {                    
                    console.log('ws opened >>>>');
                    setIsOnline(true);
                    client.send(JSON.stringify({message : 'ping'}));
                };

                client.onclose = () => {

                    if (ws.current) {
                        // Connection failed
                        console.log('ws closed by server');
                        setIsOnline(false);
                    } else {
                        // Cleanup initiated from app side, can return here, to not attempt a reconnect
                        console.log('ws closed by app component unmount');
                        return;
                    }

                    if (waitingToReconnect) {
                        return;
                    };

                    // Parse event code and log                    
                    console.log('ws closed');

                    // Setting this will trigger a re-run of the effect,
                    // cleaning up the current websocket, but not setting
                    // up a new one right away
                    setWaitingToReconnect(true);

                    // This will trigger another re-run, and because it is false,
                    // the socket will be set up again
                    setTimeout(() => setWaitingToReconnect(null), 2000);
                };

                client.onmessage = message => {
                    addMessage(message.data);
                };


                return () => {

                    console.log('Cleanup');
                    // Dereference, so it will set up next time
                    ws.current = null;

                    client.close();
                }
            }
        }
        // eslint-disable-next-line
    }, [waitingToReconnect, token]);


    useEffect(() => {
        if (!ws.current) return;

        ws.current.onmessage = e => {
            const message = JSON.parse(e.data);
            if (message && message.text && customerDetail && message.text.odoo_id === customerDetail.odoo_id) {
                if (message.text.type === 'payment_cash_notification'){          
                    
                    setListTunai([...listTunai, {'nominal' : message.text.nominal, 'time' : message.text.time}]);
                    setTotalTunai(totalTunai+parseInt(message.text.nominal))
                    setIctReady(message.text.is_connected);
                    setIctText(message.text.state);
                    setSisaSetoran(parseInt(customerDetail.total) - (totalTunai+parseInt(message.text.nominal)))

                    
                    
                    if (message.text.is_paid){
                        setIsPaid(true);
                        // setLeftScreenType('is_paid');                        
                        // setShowVa(false);                    
                        // let timer = setTimeout(() => {
                        //     setFormInput('');
                        //     setShowQr(false);
                        //     setIsPaid(false);
                        //     setShowVideo(true);
                        //     setShowInvoice(false);
                        //     handleCancel();                            
                        // }, 6000)
        
                        // return () => {
                        //     clearTimeout(timer);
                        // };
                    }                    
                }
            }
            if (message && message.text){
                if (message.text.type === 'machine_ready'){
                    setIctReady(message.text.is_ready);
                    setIctText(message.text.ict_text);
                }
            }
        };
        // eslint-disable-next-line
    }, [ws, customerDetail, listTunai]);




    return {
        alertValue, 
        ictReady, 
        ictText, 
        listTunai,
        customerDetail,
        totalTunai, 
        setIctText,
        setIctReady, 
        setAlertValue, 
        setListTunai,
        setTotalTunai,
        setCustomerDetail,
        handleCancel,
        handleCloseAlert,
        isLoading,
        sisaSetoran,
        isPaid,
        handleOpenMachine,
        printThePage,
        componentRef,
        isOnline,
    }
}