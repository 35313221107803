import DaftarTransaksiPage from "../views/DaftarTransaksi/DaftarTransaksiPage";
import HomePage from "../views/Home/HomePage";
import MethodPaymentPage from "../views/MethodPayment/MethodPaymentPage";
import PaymentCashPage from "../views/PaymentCash/PaymentCashPage";
import PaymentNonCashPage from '../views/PaymentNonCash/PaymentNonCashPage';
import PaymentEdcBcaPage from "../views/PaymentEdcBca/PaymentEdcBcaPage";

export const menu = [
	{		
		path: "/",
		element: <HomePage />,
        exact : true		
	},
	{		
		path: "/payment-method",
		element: <MethodPaymentPage />,
        exact : true		
	},
	{		
		path: "/cash-payment",
		element: <PaymentCashPage />,
        exact : true		
	},
	{		
		path: "/non-cash-payment",
		element: <PaymentNonCashPage />,
        exact : true		
	},
	{
		path: "/edc-bca-payment",
		element: <PaymentEdcBcaPage />,
		exact : true
	},
	{		
		path: "/daftar-transaksi",
		element: <DaftarTransaksiPage />,
        exact : true		
	},
]