import { Fragment } from "react"
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const AlertComp = (props) => {
    const { color, isOpen, onClose, text } = props;

    return(
        <Fragment>            
            <div className="alert">
                <div className={`flex flex-row alert-container ${isOpen ? 'alert-open' : 'alert-close'} ${color}`}>
                    <div className={`icon ${color}`}>
                        {/* <FontAwesomeIcon icon={color === 'danger' ? faTimes : color === 'success' ? faCheck : faInfo}/> */}
                        <FontAwesomeIcon icon={faCheck}/>
                    </div>
                    <div className='alert-content'>{text}</div>
                    <div className='icon-close cursor-pointer' onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>
                </div>                
            </div>            
        </Fragment>
    )
}

AlertComp.propTypes = {
    text : PropTypes.string,
    color : PropTypes.string,
    isOpen : PropTypes.bool,
    onClose : PropTypes.func,
}

AlertComp.defaultProps = {
    isOpen : false
}
export default AlertComp;